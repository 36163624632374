import { refreshAccessToken } from "./auth.js";

/**
 * @param {Array} songList An array of the song info to be searched. Each item is an object that 
 * must include isrc, album, name, year fields. Either isrc or name is required. Optional
 * parameters should be included as empty strings.
 * 
 * @returns An object with two fields, 
 * success: with an array of the successful search results
 * failed: with an array of the failed search results
*/
async function searchMultipleTracks(songList, setBackdropOpen, setProgressValue) {
    const batchSize = 20 //20
    const batchDelay = 3000
    let results = []
    let badResults = []

    await refreshAccessToken();

    setBackdropOpen(true)
    //the Search API requires each track to be searched for individually and has rate limits
    //Search for no more than 10 tracks simultaneously to prevent rate limiting
    try {
        for (let i = 0; i < songList.length; i += batchSize) {
            const promises = songList.slice(i, i + batchSize).map(async (song) => {
                let result = await searchTrack(song.isrc, song.album, song.name, song.year)

                //if the first search returns no results, try again with minimal parameters. Either isrc/name or album/name
                if (result.length === 0) {
                    if (song.isrc) result = await searchTrack(song.isrc, "", song.name, "", "", 2, 0);
                    else result = await searchTrack("", song.album, song.name, "", "", 2, 0);
                    if (result.length === 0) {
                        console.log(`Search Failed Twice: ${song.isrc}, ${song.album}, ${song.name}, ${song.year}`);
                        badResults.push(song)
                        return null
                    }
                }
                return result
            })

            const response = await Promise.all(promises)
            results.push(...response.filter(item => item !== null))

            //wait at least batchDelay/1000 seconds before moving onto the next batch to prevent rate limiting
            await new Promise(resolve => setTimeout(resolve, batchDelay))
            setProgressValue(i * 100 / songList.length)
            console.log(i);
        }
    }
    catch (error) {
        console.error('Error sending search results:', error);
    } finally {
        setBackdropOpen(false)
        setProgressValue(0)
    }
    return { success: results, failed: badResults }
}

/**
 * 
 * Sends a search query to the Spotify API to match tracks 
 * based on each song's unique isrc value.
 * 
 * Searches using the isrc and album name appear to be the most accurate, though the Spotify
 * search API has many bugs. Data read directly from a library and fed back into the search function
 * does not yield the same results.
 * 
 * Use Song Name/Album Name as a fallback if the isrc is not known or the buggy API returns bad data.
 * 
 * The year param may give more precise results but also more errors.
 * @param {string} isrc The isrc string for the track
 * @param {string} album The album name for the track
 * @param {string} name The name of the song
 * @param {string} year The year the track was released
 * @param {int} limit the number of results to return
 * @param {int} offset the offset to begin retrieving from
 * @returns a data object with an object containing the search results
 */
async function searchTrack(isrc = "", album = "", name = "", year = "", artist = "", limit = 5, offset = 0) {
    const accessToken = await refreshAccessToken();
    let q = name
    q += isrc ? ` isrc:${isrc} ` : ""
    q += album ? ` album:${album} ` : ""
    q += year ? ` year:${year} ` : ""
    q += artist ? ` artist:${artist}` : ""

    const args = new URLSearchParams({
        q: q,
        type: 'track',
        market: 'CA',
        limit: limit,
        offset: offset
    });

    const response = await fetch('https://api.spotify.com/v1/search?' + args, {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    });

    const data = await response.json();
    return data.tracks.items;
}

/**
 * 
 * @param {string} name 
 * @returns the Spotify ID of the artists that is the closest match to the input name
 */
async function searchArtist(name) {
    const accessToken = await refreshAccessToken();
    const args = new URLSearchParams({
        q: name,
        type: 'artist',
        market: 'CA',
        limit: 1,
    });

    const response = await fetch('https://api.spotify.com/v1/search?' + args, {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    });

    const data = await response.json();
    console.log(data.artists.items[0].name);
    console.log(data.artists.items[0].id);
    return data.artists.items[0].id;
}

export { searchTrack, searchMultipleTracks, searchArtist };


/**
 *
 * Sends a search query to the Amazon API to match tracks
 * based on each song's unique isrc value
 * @param {string} isrc The isrc string for the track
 * @param {string} album The album name for the track
 * @param {int} limit the number of results to return
 * @param {int} offset the offset to begin retrieving from
 * @returns a data object with an object containing the search results
 */
// async function searchAmazonTrack(isrc, album, limit = 5) {
//     const accessToken = await refreshAccessToken();
//     const body = new URLSearchParams({
//         limit: limit,
//         sortBy: 'relevance',
//         searchFilters: [{
//             "field": "isrc",
//             "query": isrc
//         },
//         {
//             "field": "albumName",
//             "query": album
//         }]
//     });
//     const response = await fetch('https://api.spotify.com/v1/search?' + args, {
//         headers: {
//             Authorization: 'Bearer ' + accessToken
//         },
//         body: body
//     });
//     const data = await response.json();
//     console.log(data);
//     return data.items;
// }
