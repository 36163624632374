import React, { useState } from 'react';
import { Box, TextField, Button, List, ListItem, ListItemText, Paper, IconButton } from '@mui/material';
import { blue, green } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

export default function ChatWindow({ onClose, messages, setMessages }) {
    const [input, setInput] = useState('');

    const handleSend = () => {
        if (input.trim()) {
            setMessages([...messages, { text: input, sender: 'user' }]);
            setInput('');
        }
    };

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSend();
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <IconButton
                onClick={onClose}
                sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
            >
                <CloseIcon />
            </IconButton>
            <List sx={{ overflow: 'auto', flexGrow: 1, marginX: 2 }}>
                {messages.map((message, index) => (
                    <ListItem key={index} sx={{ justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start' }}>
                        <Paper
                            elevation={3}
                            sx={{
                                maxWidth: '90%',
                                backgroundColor: message.sender === 'user' ? blue[300] : green['A200'],
                                padding: 1,
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                borderRadius: 2
                            }}
                        >
                            <ListItemText
                                primary={message.text}
                                secondary={message.sender === 'user' ? 'You' : 'System'}
                            />
                        </Paper>
                    </ListItem>
                ))}
            </List>
            <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Type a message..."
                    value={input}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                />
                <Button variant="contained" onClick={handleSend} sx={{ marginLeft: 1 }}>
                    Send
                </Button>
            </Box>
        </Box>
    );
}

ChatWindow.propTypes = {
    messages: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    setMessages: PropTypes.func.isRequired
}