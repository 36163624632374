import { Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function EULAPage() {
    return (
        <Container maxWidth='md' sx={{ marginTop: 1 }}>
            <Typography variant="h5" padding={2}>End User License Agreement</Typography>
            <Typography align="left" variant='body1' component='p' padding={2}>
                <ul>
                    <li>By using this website, users agree to be legally bound by the following End User License Agreement and Privacy Policy.</li>
                    <li>We do not make any warranties or representations on behalf of Spotify and expressly disclaim all implied warranties with respect to the Spotify Platform,
                        Spotify Service and Spotify Content, including the implied warranties of merchantability, fitness for a particular purpose and non-infringement. </li>
                    <li>Modifying or creating derivative works based on the Spotify Platform, Spotify Service or Spotify Content is strictly prohibited.</li>
                    <li>You may not make use of this website to aid any effort to decompile, reverse-engineer, disassemble, or otherwise reduce the Spotify Platform, Spotify Service,
                        and Spotify Content to source code or other human-perceivable form. </li>
                    <li>We are solely responsible for this website and disclaim any liability on the part of third parties, including Spotify</li>
                    <li>Spotify is a third party beneficiary of this end user license agreement and privacy policy and is entitled to directly enforce this end user license agreement. </li>
                    <li>This website is provided as-is and may contain errors or bugs. We provide no representation of the correctness of any functionality or information provided.</li>
                    <li>This website is intended to be used for entertainment purposes and only for personal use. Any commercial, educational or government use is prohibited.</li>
                    <li>In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising
                        from loss of data or profits arising out of, or in connection with, the use of this website.</li>
                    <li>This website is a 3rd party service and is not directly affiliated with Spotify. </li>
                    <li>Any and all content provided by Spotify remains the exclusive property of Spotify Inc.</li>
                    <li>By using this website, users agree to be bound by the &nbsp;
                        <Link target='_blank' rel='noreferrer' variant='inherit' underline='always' href='https://www.spotify.com/ca-en/legal/end-user-agreement/'>Spotify Terms of Use</Link>.</li>
                </ul>
            </Typography>
        </Container>
    )
}