import { Container, Typography } from "@mui/material";

export default function PrivacyPage() {
    return (
        <Container maxWidth='md' sx={{ marginTop: 1 }}>
            <Typography variant="h5" padding={2}>Privacy Policy</Typography>
            <Typography align="left" variant='body1' component='p' padding={2}>
                <ul>
                    <li>Any activity on this site and use of data is subject to this Privacy Policy.</li>
                    <li>We do not collect any personal information from your Spotify account or any analytics data from the use of this site. In fact, we do not run any servers or databases so no data can be stored.
                        No personal data is sent to us and all communications are directly from your device to the Spotify service. Data from your Spotify account is stored solely on your local device
                        and only used to deliver content and services for your personal use.</li>
                    <li>We do not use any cookies whatsoever (including 3rd party cookies).</li>
                    <li>Your data will not, and in fact can not, be sold to any 3rd parties because we don't possess any data to sell.</li>
                    <li>Any inquiries regarding the Privacy Policy can be made to tools.spotify@outlook.com</li>
                </ul>
            </Typography>
            <Typography align="left" variant='body1' component='p' padding={2}>
                If you wish to stop using this service and revoke this site's limited access to your Spotify account, please follow these steps:
            </Typography>
            <Typography align="left" variant='body1' component='p' padding={2} sx={{ marginLeft: 5 }}>
                <ol>
                    <li>Access your Account page on the Spotify website.</li>
                    <li>Scroll down until you find the 'Manage apps' option and click it.</li>
                    <li>Find the 'Tools For Spotify' entry and click 'Remove Access'.</li>
                </ol>
            </Typography>
            <Typography align="left" variant='body1' component='p' padding={2}>
                Your account is now successfully disconnected from this website and none of your data will be saved. To continue using the website, log in to
                your account and approve access once again.
            </Typography>
        </Container>
    )
}