import { useState, useEffect } from "react";

/**
 * @param key the storage key to be written to localStorage
 * @param defaultValue the default value for the data if not provided
 * @returns the stateful value and setter function
 * 
 * A custom hook that functions as a useState hook but also writes the stateful
 * value to localStorage to be persisted.
 */
function useLocalStorage(key, defaultValue = null) {
    const [value, setValue] = useState(() => {
        try {
            const saved = localStorage.getItem(key);
            if (saved !== null) {
                return JSON.parse(saved);
            }
            return defaultValue;
        } catch {
            return defaultValue;
        }
    });
    useEffect(() => {
        const rawValue = JSON.stringify(value);
        localStorage.setItem(key, rawValue);
    }, [key, value]);

    return [value, setValue];
};

export { useLocalStorage }