import '../App.css';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import TableSortLabel from '@mui/material/TableSortLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useState, useMemo, Fragment } from 'react';
import { getShows, getShowEpisodes } from '../utils/data.js'
import { getComparator, stableSort } from '../utils/sort';
import { useLocalStorage } from '../utils/util';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

const columns = [
    { id: 'num', label: '', minWidth: 30, sortable: false, align: 'center' },
    { id: 'name', label: 'Title', minWidth: 170, sortable: true, align: 'center' },
    { id: 'description', label: 'Description', minWidth: 300, sortable: false, align: 'center' },
    { id: 'total_episodes', label: 'Total Episodes', minWidth: 30, sortable: true, align: 'right' },
];

//Should the collapseable table have a sticky header as well?
/**
 * 
 * @param {*} props 
 * @returns 
 */
function CollapseableRow({ row }) {
    const [open, setOpen] = useState(false);
    const [episodeData, setEpisodeData] = useState([]);
    const [offset, setOffset] = useState(0); //do I want to persist this between page loads? Might be more appropriate for sessionStorage with all the daily episode updates


    //TODO: combine these two functions and strip out the setOpen logic
    /**
     * 
     * @param {string} id the Spotify show id
     * On every call, including the first, fetch 50 new episodes
     */
    const loadEpisodeData = async (id) => {
        if (open) {
            setOpen(!open)
            return
        }

        const data = await getShowEpisodes(id, 50, offset);
        const parsedData = data.map((row) => {
            return { name: row.name, duration: Math.round(row.duration_ms / 1000), description: row.description, release_date: row.release_date, link: row.external_urls.spotify }
        })
        setEpisodeData([...episodeData, ...parsedData]);
        console.log(parsedData);
        console.log("Episode data is:", episodeData);
        setOpen(!open)
        setOffset(offset + 50);
    };

    /**
         * 
         * @param {string} id the Spotify show id
         * On every call, fetch 50 new episodes
         */
    const loadMoreEpisodeData = async (id) => {
        const data = await getShowEpisodes(id, 50, offset);
        const parsedData = data.map((row) => {
            return { name: row.name, duration: Math.round(row.duration_ms / 1000), description: row.description, release_date: row.release_date, link: row.external_urls.spotify }
        })
        setEpisodeData([...episodeData, ...parsedData]);
        console.log(parsedData);
        console.log("Episode data is:", episodeData);
        setOffset(offset + 50);
    };

    /***
     * Creates a txt file with the episode descriptions that can also be used as a tsv file
     */
    const downloadNotes = async () => {
        // Create a Blob containing the text or data you want to save.
        let data = episodeData.map((row) => {
            return row.name + ": " + row.release_date + "\t" + row.description + "\n";
        })
        console.log(data);
        data = data.join("\n");

        const blob = new Blob([data], { type: "text/plain" });
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = row.name + "-" + Date.now() + ".txt";
        a.click();
        window.URL.revokeObjectURL(a.href);
    }

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => loadEpisodeData(row.id)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" key='name' sx={{ fontWeight: 'bold' }}> {row.name}</TableCell>
                <TableCell key='description' > {row.description}</TableCell>
                <TableCell key='total_episodes' align='right'> {row.total_episodes}</TableCell>
                <TableCell key='link' >
                    <a href={row.link} target="_blank" rel="noreferrer"><img src={row.image} alt="Song's Album Cover"></img></a>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography sx={{ marginRight: 3 }} display={'inline-block'} variant="h6" gutterBottom component="div">
                                Episodes
                            </Typography>
                            <Button sx={{ marginRight: 3 }} name='download' color='secondary' variant='contained' onClick={() => loadMoreEpisodeData(row.id)}>Load 50 More Episodes</Button>
                            <Button name='download' color='secondary' variant='contained' onClick={downloadNotes}>Download Show Notes</Button>
                            <Table size="small" aria-label="episodes">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>Name</TableCell>
                                        <TableCell align='center'>Description</TableCell>
                                        <TableCell align="right">Duration(s)</TableCell>
                                        <TableCell align="right">Release Date</TableCell>
                                        <TableCell>Play Episode</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {episodeData.map((episodeRow) => (
                                        <TableRow key={episodeRow.name}>
                                            <TableCell component="th" scope="row">
                                                {episodeRow.name}
                                            </TableCell>
                                            <TableCell>{episodeRow.description}</TableCell>
                                            <TableCell align="right">{episodeRow.duration}</TableCell>
                                            <TableCell align="right">{episodeRow.release_date}</TableCell>
                                            <TableCell key='link' >
                                                <a href={episodeRow.link} target="_blank" rel="noreferrer">Play</a>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

CollapseableRow.propTypes = {
    row: PropTypes.shape({
        description: PropTypes.string.isRequired,
        duration_ms: PropTypes.number.isRequired,
        episodes: PropTypes.arrayOf(
            PropTypes.shape({
                description: PropTypes.string.isRequired,
                duration: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                release_date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        external_urls: PropTypes.shape({
            spotify: PropTypes.string.isRequired
        }),
        id: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        release_date: PropTypes.string.isRequired,
        total_episodes: PropTypes.number.isRequired,
    }).isRequired,
};

export default function PodcastsTable() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rows, setRows] = useLocalStorage('PodcastsData', []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const loadPodcasts = async ({ limit, offset }) => {
        const data = await getShows(limit, offset)
        const parsedData = data.map((row) => {
            return { id: row.show.id, name: row.show.name, description: row.show.description, total_episodes: row.show.total_episodes, episodes: [], link: row.show.external_urls.spotify, image: row.show.images[2].url }
        })
        setRows(parsedData);
        console.log(parsedData);
    }

    const sortColumn = (property) => {
        //isAsc is true if the selected property matches and the current order is ascending
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc'); //toggle the order if necessary
        setOrderBy(property);
    };

    const visibleRows = useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, rows],
    );

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Button sx={{ margin: 1 }} name='load-podcasts' color='secondary' variant='contained' onClick={() => loadPodcasts({ limit: 50, offset: 0 })}>Get Podcasts</Button>

            <TableContainer sx={{ maxHeight: 1200, bgcolor: 'PaleTurquoise' }}>
                <Table stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.sortable ?
                                        (<TableSortLabel onClick={() => sortColumn(column.id)}
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'desc'}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                        ) : (
                                            column.label
                                        )}
                                </TableCell>
                            ))}
                            <TableCell align='center'><LaunchOutlinedIcon /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visibleRows.map((row) => {
                            return <CollapseableRow key={row.name} row={row} />
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}