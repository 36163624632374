import { Button, Paper, Slider, Divider, TextField, Typography, Checkbox, Dialog, useMediaQuery, useTheme } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Autocomplete from "@mui/material/Autocomplete";
import { getRecommendations, getGenreSeeds } from "../utils/data";
import SongsTable from "./SongsTable";
import { forwardRef, useMemo, useState } from "react";
import { useLocalStorage } from "../utils/util";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Grid from '@mui/material/Unstable_Grid2';
import FormGroup from '@mui/material/FormGroup';
import PropTypes from 'prop-types';
import QuestionAnswerSharpIcon from '@mui/icons-material/QuestionAnswerSharp';
import IconButton from '@mui/material/IconButton';
import { purple } from "@mui/material/colors";
import OpenAIChatControl from "./OpenAIChatControl";
import { searchArtist } from "../utils/search";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const genreList = await getGenreSeeds()

const columnWidths = { xs: 12, sm: 12, md: 6, lg: 6, xl: 4 }

export default function RecommendationPage({ songsData = [] }) {
    const tempoMarks = [{ value: 0, label: '0' }, { value: 50, label: '50' }, { value: 100, label: '100' }, { value: 150, label: '150' },
    { value: 200, label: '200' }, { value: 250, label: '250' }, { value: 300, label: '300' }]

    const [songsPicked, setSongsPicked] = useLocalStorage('songsPicked', [])
    const [artistsPicked, setArtistsPicked] = useLocalStorage('artistsPicked', [])
    const [genrePicked, setGenrePicked] = useLocalStorage('genrePicked', null)
    const [resultsData, setResultsData] = useLocalStorage('recommendationResults', [])
    const [showTable, setShowTable] = useLocalStorage('showRecommendationTable', false)
    const [showSongsInLibrary, setShowSongsInLibrary] = useState(false)
    const [showNoPreview, setShowNoPreview] = useState(false)
    const [showChatWindow, setShowChatWindow] = useState(false)

    //save these in localstorage?
    const [danceability, setDanceability] = useState([50, 100])
    const [danceabilityOn, setDanceabilityOn] = useState(false)
    const [energy, setEnergy] = useState([75, 100])
    const [energyOn, setEnergyOn] = useState(false)
    const [instrumentalness, setInstrumentalness] = useState([0, 30])
    const [instrumentalnessOn, setInstrumentalnessOn] = useState(false)
    const [popularity, setPopularity] = useState([60, 100])
    const [popularityOn, setPopularityOn] = useState(true)
    const [tempo, setTempo] = useState([100, 200])
    const [tempoOn, setTempoOn] = useState(false)
    const [valence, setValence] = useState([60, 80])
    const [valenceOn, setValenceOn] = useState(false)

    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [errorSnackbarMessage, setErrorSnackbarMessage] = useState('')

    const theme = useTheme();
    const chatFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const clearParameters = () => {
        setDanceabilityOn(false)
        setEnergyOn(false)
        setInstrumentalnessOn(false)
        setPopularityOn(false)
        setTempoOn(false)
        setValenceOn(false)
        setSongsPicked([])
        setArtistsPicked([])
        setGenrePicked(null)
    }

    /**
     * Callback used by the chat interface to send the response message up to this parent component
     * @param {object} response The tool_call function object returned by the OpenAI chat completions endpoint
     */
    const updateStateFromChat = async (response) => {
        const songNotFoundMessage = {
            text: "Sorry, I'm having a little trouble matching the title of the song to the songs in your library. Please try again or fill in the title by hand.",
            sender: 'System'
        }
        
        let newMessages = []
        clearParameters()
        console.log(response);
        
        //Chat will randomly return lowercase or capitalized values, so convert to lowercase before comparing
        //If there are two songs with the same name by different artists...it's not obvious or easy to handle that
        //so just pick the first occurance and hope for the best
        if ('seed_tracks' in response) {
            let songs = []
            response.seed_tracks.forEach((name) => {
                //check to see if the song is part of the user's library; if so, we already have the Spotify ID
                const found = songList.find((song) => song.name.toLowerCase() === name.toLowerCase())
                if (found) {
                    songs.push(found)
                    console.log("song found");
                } else { //if not, search for the Spotify ID?
                    //Song titles are difficult to match just based on string comparisons due to formatting and (ft. artist X and Y) etc.
                    //Matching user input and AI output where both may be incorrect is quite complicated...
                    //For now, just ignore any that don't match and the user can search their own library for matches
                    newMessages.push(songNotFoundMessage)
                    console.log(name);
                }
            })
            setSongsPicked(songs)
        }

        if ('seed_artists' in response) {
            let artists = []
            response.seed_artists.forEach(async (name) => {
                //check to see if the artists are part of the user's library; if so, we already have the Spotify ID
                let found = artistList.find((artist) => artist.name.toLowerCase() === name.toLowerCase())
                if (found) {
                    artists.push(found)
                } else { //if not, search for the Spotify ID
                    const id = await searchArtist(name)

                    if(id) {
                        found = artistList.find((artist) => artist.id === id)
                        artists.push(found)
                        console.log('Found artist by search: ', found)
                    } else {
                        console.log("Artist not found: ", name);
                    }
                }
            })
            setArtistsPicked(artists)
        }

        if ('seed_genre' in response) {
            if (genreList.includes(response.seed_genre.toLowerCase())) {               
                setGenrePicked(response.seed_genre.toLowerCase())
            }
        }

        if ('target_danceability' in response) {
            const target = response.target_danceability * 100
            const min = Math.max(0, target - 10)
            const max = Math.min(100, target + 10)
            setDanceability([min, max])
            setDanceabilityOn(true)
        }

        if ('target_energy' in response) {
            const target = response.target_energy * 100
            const min = Math.max(0, target - 10)
            const max = Math.min(100, target + 10)
            setEnergy([min, max])
            setEnergyOn(true)
        }

        if ('target_instrumentalness' in response) {
            const target = response.target_instrumentalness * 100
            const min = Math.max(0, target - 10)
            const max = Math.min(100, target + 10)
            setInstrumentalnessOn(true)
            setInstrumentalness([min, max])
        }

        if ('target_popularity' in response) {
            const target = response.target_popularity
            const min = Math.max(0, target - 10)
            const max = Math.min(100, target + 10)
            setPopularityOn(true)
            setPopularity([min, max])
        }

        if ('target_tempo' in response) {
            const target = response.target_tempo
            const min = Math.max(0, target - 20)
            const max = Math.min(100, target + 20)
            setTempoOn(true)
            setTempo([min, max])
        }
        
        if ('target_valence' in response) {
            const target = response.target_valence * 100
            const min = Math.max(0, target - 10)
            const max = Math.min(100, target + 10)
            setValenceOn(true)
            setValence([min, max])
        }
        return newMessages
    }

    const handleOpenChatMenu = () => {
        setShowChatWindow(true)
    }

    const handleCloseChatMenu = () => {
        setShowChatWindow(false)
    }

    const handleErrorSnackbarClose = () => {
        setErrorSnackbarOpen(false);
    };

    const handleHideLibrary = (event) => {
        setShowSongsInLibrary(event.target.checked)
    }

    const handleHidePreview = (event) => {
        setShowNoPreview(event.target.checked)
    }

    const reset = () => {
        setShowTable(false)
        setArtistsPicked([])
        setSongsPicked([])
        setGenrePicked(null)
        setInstrumentalness([0, 30])
        setInstrumentalnessOn(false)
        setDanceability([50, 100])
        setDanceabilityOn(false)
        setEnergy([75, 100])
        setEnergyOn(false)
        setPopularity([60, 100])
        setPopularityOn(true)
        setValence([60, 80])
        setValenceOn(false)
        setTempo([100, 200])
        setTempoOn(false)
    }

    const songList = useMemo(
        () => {
            let songs = songsData.map((song) => {
                return { name: song.name, id: song.id, artists: song.artists.split(',')[0] } //pick only the first artist because otherwise it's too cluttered
            })
            songs.sort((a, b) => {
                if (a.artists.localeCompare(b.artists) === 0) {
                    return a.name.localeCompare(b.name)
                }
                return a.artists.localeCompare(b.artists)
            })
            console.log('Song List Refreshed');
            return songs
        }, [songsData]
    )

    //list of unique artists in the form {name:'Artist_Name', id: 'Spotify_artist_id'}
    const artistList = useMemo(
        () => {
            const allValues = songsData.map(song => song.artistsIDs).flat()
            let uniqueValues = [...new Map(allValues.map(item => [item.id, item])).values()]
            uniqueValues.sort((a, b) => a.name.localeCompare(b.name))
            return uniqueValues
        }, [songsData]
    )

    const handleSongPickerChange = (event, newValue) => setSongsPicked(newValue)
    const handleArtistPickerChange = (event, newValue) => setArtistsPicked(newValue)
    const handleGenrePickerChange = (event, newValue) => setGenrePicked(newValue)

    const handleSliderChange = (event, newValue) => {
        switch (event.target.name) {
            case 'Energy':
                setEnergy(newValue);
                break;
            case 'Popularity':
                setPopularity(newValue);
                break;
            case 'Tempo':
                setTempo(newValue);
                break;
            case 'Danceability':
                setDanceability(newValue);
                break;
            case 'Instrumentalness':
                setInstrumentalness(newValue);
                break;
            case 'Valence':
                setValence(newValue);
                break;
            default:
                console.error('No such slider found');
        }
    };

    const handleSwitchChange = (event, newValue) => {
        switch (event.target.name) {
            case 'EnergySwitch':
                setEnergyOn(newValue);
                break;
            case 'PopularitySwitch':
                setPopularityOn(newValue);
                break;
            case 'TempoSwitch':
                setTempoOn(newValue);
                break;
            case 'DanceabilitySwitch':
                setDanceabilityOn(newValue);
                break;
            case 'InstrumentalnessSwitch':
                setInstrumentalnessOn(newValue);
                break;
            case 'ValenceSwitch':
                setValenceOn(newValue);
                break;
            default:
                console.error('No such switch found');
        }
    }

    const loadRecommendations = async () => {
        //check the no more than 5 seeds were selected (5+ will cause a bad request error)
        if ((songsPicked.length + artistsPicked.length + genrePicked) > 5) {
            setErrorSnackbarMessage('Too many seeds! Must be 5 or less, including genre.')
            setErrorSnackbarOpen(true)
            return
        }

        if ((songsPicked.length + artistsPicked.length + genrePicked) === 0) {
            setErrorSnackbarMessage('You must select at least one seed.')
            setErrorSnackbarOpen(true)
            return
        }

        const inputs = {
            limit: 100,
            ...(energyOn && { min_energy: energy[0] / 100, max_energy: energy[1] / 100 }),
            ...(popularityOn && { min_popularity: popularity[0], max_popularity: popularity[1] }),
            ...(tempoOn && { min_tempo: tempo[0], max_tempo: tempo[1] }),
            ...(danceabilityOn && { min_danceability: danceability[0] / 100, max_danceability: danceability[1] / 100 }),
            ...(instrumentalnessOn && { min_instrumentalness: instrumentalness[0] / 100, max_instrumentalness: instrumentalness[1] / 100 }),
            ...(valenceOn && { min_valence: valence[0] / 100, max_valence: valence[1] / 100 }),
            ...(songsPicked.length && { seed_tracks: songsPicked.map((song) => song.id).join(',') }),
            ...(artistsPicked.length && { seed_artists: artistsPicked.map((artist) => artist.id).join(',') }),
            ...(genrePicked && { seed_genres: genrePicked }),
        }
        console.log(inputs);
        const results = await getRecommendations(inputs)
        const parsedData = results.tracks.map((track, index) => {
            const artists = track.artists.map((artist) => { return artist.name }).join(", ")
            const duration = new Date(track.duration_ms).toISOString().slice(15, 19); //change to m:ss format
            return {
                name: track.name, duration: duration, artists: artists,
                popularity: track.popularity, link: track.external_urls.spotify, image: track.album.images[2].url,
                uri: track.uri, id: track.id, preview: track.preview_url, index: index
            }
        })
        setResultsData(parsedData)
        setShowTable(true)
    }

    return (
        <Paper sx={{ bgcolor: 'white' }}>
            {showTable ? (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                        <Button sx={{ margin: 2 }} variant='outlined' onClick={() => setShowTable(!showTable)}>Find More Recommendations</Button>
                    </div>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={showSongsInLibrary} onChange={handleHideLibrary} />} label="Show Songs Already In Your Library" />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={showNoPreview} onChange={handleHidePreview} />} label="Show Songs Without Preview Clips" />
                    </FormGroup>
                    <SongsTable type='recommendations' inputData={resultsData} setInputData={setResultsData} showInLibrary={showSongsInLibrary} showNoPreview={showNoPreview} />
                </div>
            ) : (
                <div>
                    <Grid container rowSpacing={5} columnSpacing={5} margin={2} >
                        <Grid xs={12}>
                            <Divider variant="fullWidth"></Divider>
                        </Grid>
                        <Grid xs={12}>
                            <Typography variant="h6">Choose up to 5 seeds in total</Typography>
                        </Grid>
                        <Grid {...columnWidths}>
                            <Autocomplete value={songsPicked} name='SongPicker' disableCloseOnSelect filterSelectedOptions fullWidth handleHomeEndKeys id='songpicker' limitTags={-1} multiple onChange={handleSongPickerChange}
                                options={songList}
                                groupBy={(option) => option.artists}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => {
                                    return option.name === value.name && option.id === value.id && option.artists === value.artists
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Pick Songs"
                                        placeholder=""
                                    />
                                )}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            {option.name}
                                        </li>
                                    );
                                }}
                            />
                        </Grid>
                        <Grid {...columnWidths}>
                            <Autocomplete value={artistsPicked} name='ArtistPicker' disableCloseOnSelect filterSelectedOptions fullWidth handleHomeEndKeys id='artistpicker' limitTags={-1} multiple onChange={handleArtistPickerChange}
                                options={artistList}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => {
                                    return option.name === value.name && option.id === value.id
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Pick Artists"
                                        placeholder=""
                                    />
                                )}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            {option.name}
                                        </li>
                                    );
                                }}
                            />
                        </Grid>
                        <Grid {...columnWidths}>
                            <Autocomplete value={genrePicked} name='GenrePicker' filterSelectedOptions fullWidth handleHomeEndKeys id='genrepicker' onChange={handleGenrePickerChange}
                                options={genreList}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Select a genre"
                                        placeholder=""
                                    />
                                )} />
                        </Grid>
                        <Grid xs={12}>
                            <Divider variant="fullWidth"></Divider>
                        </Grid>
                        <Grid xs={12}>
                            <Typography variant="h6">Refine the recommendations with any of these optional values</Typography>
                        </Grid>
                        <Grid {...columnWidths}>
                            <FormControlLabel control={<Switch name="PopularitySwitch" checked={popularityOn} onChange={handleSwitchChange} />} label="Popularity" />
                            {popularityOn && <Slider name='Popularity' value={popularity} onChange={handleSliderChange} valueLabelDisplay="auto" />}
                        </Grid>
                        <Grid {...columnWidths}>
                            <FormControlLabel control={<Switch name="EnergySwitch" checked={energyOn} onChange={handleSwitchChange} color="secondary" />} label="Energy" />
                            {energyOn && <Slider name='Energy' value={energy} onChange={handleSliderChange} valueLabelDisplay="auto" color="secondary" />}
                        </Grid>
                        <Grid {...columnWidths}>
                            <FormControlLabel control={<Switch name="DanceabilitySwitch" checked={danceabilityOn} onChange={handleSwitchChange} color="secondary" />} label="Danceability" />
                            {danceabilityOn && <Slider name='Danceability' value={danceability} onChange={handleSliderChange} valueLabelDisplay="auto" color="secondary" />}
                        </Grid>
                        <Grid {...columnWidths}>
                            <FormControlLabel control={<Switch name="TempoSwitch" checked={tempoOn} onChange={handleSwitchChange} />} label="Tempo" />
                            {tempoOn && <Slider name='Tempo' value={tempo} onChange={handleSliderChange} valueLabelDisplay="auto" max={300} marks={tempoMarks} />}
                        </Grid>
                        <Grid {...columnWidths}>
                            <FormControlLabel control={<Switch name="InstrumentalnessSwitch" checked={instrumentalnessOn} onChange={handleSwitchChange} />} label="Instrumentalness" />
                            {instrumentalnessOn && <Slider name='Instrumentalness' value={instrumentalness} onChange={handleSliderChange} valueLabelDisplay="auto" />}
                        </Grid>
                        <Grid {...columnWidths}>
                            <FormControlLabel control={<Switch name="ValenceSwitch" checked={valenceOn} onChange={handleSwitchChange} />} label="Valence(Happiness/Mood)" />
                            {valenceOn && <Slider name='Valence' value={valence} onChange={handleSliderChange} valueLabelDisplay="auto" />}
                        </Grid>
                        <Grid xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={showSongsInLibrary} onChange={handleHideLibrary} />} label="Show Songs Already In Your Library" />
                            </FormGroup>
                        </Grid>
                        <Grid xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={showNoPreview} onChange={handleHidePreview} />} label="Show Songs Without Preview Clips" />
                            </FormGroup>
                        </Grid>
                        <Grid xs={12}>
                            <IconButton onClick={handleOpenChatMenu} sx={{ p: 0 }}>
                                <QuestionAnswerSharpIcon fontSize="large" sx={{ color: purple[800] }} />
                                <Typography>Get help from the AI Chat Assistant</Typography>
                            </IconButton>
                        </Grid>
                        <Grid {...columnWidths}>
                            <Button sx={{ width: '100%' }} variant='outlined' onClick={loadRecommendations}>Get Recommendations</Button>
                        </Grid>
                        <Grid {...columnWidths} xsOffset='auto'>
                            <Button sx={{ width: '100%' }} variant='outlined' color='error' onClick={reset}>Reset Selections</Button>
                        </Grid>
                    </Grid>
                    <Dialog open={showChatWindow} onClose={handleCloseChatMenu} fullScreen={chatFullScreen} fullWidth maxWidth="md"
                        PaperProps={{
                            style: {
                                height: '90vh',
                                maxHeight: '90vh',
                            },
                        }}
                    >
                        <OpenAIChatControl onClose={handleCloseChatMenu} updateStateFromChat={updateStateFromChat} />
                    </Dialog>
                </div>

            )}
            <Snackbar open={errorSnackbarOpen} onClose={handleErrorSnackbarClose} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                <Alert onClose={handleErrorSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {errorSnackbarMessage}
                </Alert>
            </Snackbar>
        </Paper >
    )
}

RecommendationPage.propTypes = {
    songsData: PropTypes.array.isRequired
}