import { refreshAccessToken } from "./auth.js";
import { searchMultipleTracks } from "./search.js";
import { getProfile } from "./data.js";

/**
 * Loads a CSV file from the user's disk and processes it to load each song into
 * the user's library.
 * Searches first for a 'Spotify URI' field, then an 'International Standard Recording Code(ISRC)'.
 * If neither are present, use the 'Song Name' and 'Album Name' combination to run a search and use the first result.
 * 
 * Expects the first line to be the column index.
 * @param {Event} fileEvent 
 * @returns a list of search results or an empty array if the songs were automatically added
 * Array of spotify URIs to be added
 */
async function importCSV(inputFile, playlistName, setBackdropOpen, setProgressValue) {
    let songList = [] //list of URIs to send to add to the library/playlist
    // const inputFile = fileEvent.target.files[0]
    const csv = await inputFile.text()
    const lines = csv.split('\n')

    //remove and process the first line to discover the format
    const indexLine = lines.shift().split(',')
    if (lines[lines.length - 1] === "") lines.pop() //strip out blank last line, if any

    const isrcField = indexLine.indexOf('International Standard Recording Code(ISRC)')
    const albumField = indexLine.indexOf('Album Name')
    const nameField = indexLine.indexOf('Song Name')
    const yearField = indexLine.indexOf('Release Year')

    //if the spotify URIs are present, no need to use search functionality just import directly
    const URIField = indexLine.indexOf('Spotify URIxxx') //testing
    if (URIField > -1) {
        console.log("URIs found");
        songList = lines.map((line) => {
            return line.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g)[URIField]
        })
        console.log("Songlist is: ", songList);
        const playlistID = await createPlaylist(playlistName)
        addTracksToPlaylist(songList, playlistID)
        // return songList
        return []
    }

    //if there are no spotify URIs, such as if the CSV comes from another streaming service, build a list of 
    //search parameters for the available fields in the CSV
    const searchList = lines.map((line) => {
        const fields = line.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g)
        const item = {}
        item.isrc = (isrcField > -1) ? fields[isrcField] : ""
        item.year = (yearField > -1) ? fields[yearField] : ""
        //name and album fields may have embedded quotes so remove those
        item.album = (albumField > -1) ? fields[albumField].replace(/"/g, '') : ""
        item.name = (nameField > -1) ? fields[nameField].replace(/"/g, '') : ""
        return item
    })
    console.log(searchList);
    const searchResults = await searchMultipleTracks(searchList, setBackdropOpen, setProgressValue)
    return searchResults
}

/**
 * A maxiumum of 50 items can be added at a time
 * @param {*} songList 
 */
async function addSongsToLibrary(songList) {
    const accessToken = await refreshAccessToken();

    //remove the spotify:track: part of the string since that is only used for adding to playlists but not 
    //adding to a user's library (for some reason)
    const songIDs = songList.map((item) => {
        return item.slice(15)
    })

    for (let i = 0; i < songIDs.length; i += 50) {
        const inputs = songIDs.slice(i, i + 50)
        const body = JSON.stringify({ ids: inputs })

        try {
            const response = await fetch(`https://api.spotify.com/v1/me/tracks`,
                {
                    headers: {
                        Authorization: 'Bearer ' + accessToken,
                        'Content-Type': 'application/json'
                    },
                    body: body,
                    method: "PUT"
                });
            console.log(await response.json());
        } catch (error) {
            console.error(error);
        }

    }
}

/**
 * A maximum of 100 tracks can be added per API call
 * @param {Array<string>} inputList 
 * @param {string} playlistID 
 */
async function addTracksToPlaylist(inputList, playlistID) {
    const accessToken = await refreshAccessToken();

    for (let i = 0; i < inputList.length; i += 100) {
        const inputs = inputList.slice(i, i + 100)
        const body = JSON.stringify({ uris: inputs, position: i })

        try {
            await fetch(`https://api.spotify.com/v1/playlists/${playlistID}/tracks`,
                {
                    headers: {
                        Authorization: 'Bearer ' + accessToken,
                        'Content-Type': 'application/json'
                    },
                    body: body,
                    method: "POST"
                });
        } catch (error) {
            console.error(error);
        }
    }
}

/**
 * 
 * Creates a new playlist with the provided name
 * @param {string} name The name of the playlist to create
 * @returns the Spotify ID of the playlist
 */
async function createPlaylist(name) {
    const accessToken = await refreshAccessToken();
    const profile = await getProfile();
    const userID = profile.id

    const body = JSON.stringify({ name: name, public: false, collaborative: false, description: "Auto-generated playlist based off of search results" })

    const response = await fetch(`https://api.spotify.com/v1/users/${userID}/playlists`,
        {
            headers: {
                Authorization: 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            },
            body: body,
            method: "POST"
        });

    const data = await response.json();
    console.log(data);
    console.log(data.id);
    return data.id
}

/**
 * 
 * @param {string} id a single Spotify ID to be added to the library
 * @returns true if successful, false otherwise
 */
async function addSongToLibrary(id) {
    const accessToken = await refreshAccessToken();

    try {
        await fetch(`https://api.spotify.com/v1/me/tracks?ids=${id}`,
            {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                },
                method: "PUT"
            });
        return true
    } catch (error) {
        console.error('Error adding song to library', error);
        return false
    }
}

/**
 * 
 * @param {string} id a single Spotify ID to be deleted from the library
 * @returns true if successful, false otherwise
 */
async function removeSongFromLibrary(id) {
    const accessToken = await refreshAccessToken();

    try {
        await fetch(`https://api.spotify.com/v1/me/tracks?ids=${id}`,
            {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                },
                method: "DELETE"
            });
        return true
    } catch (error) {
        console.error('Error removing song from library', error);
        return false
    }
}

export { importCSV, addTracksToPlaylist, addSongsToLibrary, createPlaylist, addSongToLibrary, removeSongFromLibrary }