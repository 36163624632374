const clientId = '24972772eee340079006d8adf086757e';
let redirectUri = 'https://music.chriswon.ca'

if (process.env.REACT_APP_ENV === 'dev') {
    // Development code
    console.log('Development Mode: ', process.env.REACT_APP_ENV);
    redirectUri = 'https://music.chriswon.ca/dev/index.html'
} else if (process.env.REACT_APP_ENV === 'test') {
    // Production code
    redirectUri = 'http://localhost:3000'
    console.log('Development Mode: ', process.env.REACT_APP_ENV);
} else {
    // Production code
    redirectUri = 'https://music.chriswon.ca'
    console.log('Development Mode: ', process.env.REACT_APP_ENV);
}

/**
 * 
 * @param {int} length the length of the random string
 * @returns a random string of the desired length, used for PCKE code challenge
 */
function generateRandomString(length) {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

/**
 * 
 * @param {string} codeVerifier random string to be hashed
 * @returns a PCKE code challenge SHA-256 hash value
 */
async function generateCodeChallenge(codeVerifier) {
    function base64encode(string) {
        return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '');
    }

    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);

    return base64encode(digest);
}

/**
 * 
 */
async function login() {
    if (localStorage.getItem('refresh_token')) logout();
    await getAuthCode()
}

function logout() {
    localStorage.clear();
}

/**
 * Uses the Authorization code with PCKE flow to get an authentication code and store it locally
 */
async function getAuthCode() {
    let codeVerifier = generateRandomString(128);
    localStorage.setItem('code_verifier', codeVerifier);

    //call the authorize endpoint and receive an auth code if successful
    const codeChallenge = await generateCodeChallenge(codeVerifier)
    let state = generateRandomString(16);
    let scope = 'user-read-email user-read-private user-library-read playlist-modify-private user-top-read user-library-modify';

    let args = new URLSearchParams({
        response_type: 'code',
        client_id: clientId,
        scope: scope,
        redirect_uri: redirectUri,
        state: state,
        code_challenge_method: 'S256',
        code_challenge: codeChallenge
    });

    window.location = 'https://accounts.spotify.com/authorize?' + args;
}

/**
 * Uses the Authorization code with PCKE flow to trade an auth code for an access and refresh token and stores them locally
 */
async function getAuthToken() {
    const urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get('code');
    let codeVerifier = localStorage.getItem('code_verifier');

    let body = new URLSearchParams({
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: redirectUri,
        client_id: clientId,
        code_verifier: codeVerifier
    });

    //exchange auth code for auth token
    try {
        const response = await fetch('https://accounts.spotify.com/api/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body
        })

        const data = await response.json();
        if (!response.ok) {
            console.log("unsuccessful response");
            throw new Error('HTTP status: ' + response.status + ", Error Message " + data.error_description);
        }
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        const now = new Date();
        now.setHours(now.getHours() + 1);
        localStorage.setItem('access_token_expires_at', now.getTime());

        //remove the querystring from the url in the address bar
        const url = new URL(window.location);
        url.search = '';
        console.log(url);
        window.history.pushState('', document.title, url);

        return data.access_token;
    } catch (error) {
        console.error('Error:', error);
    }
}

/**
 * Checks whether the access token has expired and if so, 
 * Sends the Refresh token to get a new set of Access/Refresh Tokens
 * (Refresh tokens do not expire until user revokes access).
 * 
 * Should be called before every API call since it will simply return if the token is still valid.
 * 
 * @returns Returns a valid access token to prevent race conditions when reading from localStorage
 */
async function refreshAccessToken() {
    //if the auth code is present in the query string, we need to first get an access and refresh token
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('code')) {
        return await getAuthToken();
    }

    const expires = localStorage.getItem('access_token_expires_at')
    if (Date.now() < expires) {
        return localStorage.getItem('access_token')
    }

    // console.log('Refreshing access token')
    let refresh_token = localStorage.getItem('refresh_token');

    let body = new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: refresh_token,
        client_id: clientId,
    });

    //exchange refresh token for auth token
    try {
        const response = await fetch('https://accounts.spotify.com/api/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body
        })

        const data = await response.json();
        if (!response.ok) {
            console.log("unsuccessful attempt to refresh access token");

            if (data.error_description === "Refresh token revoked") {
                localStorage.removeItem('refresh_token');
            }
            throw new Error('HTTP status: ' + response.status + ", Error Message " + data.error_description);
        }

        console.log("successfully refreshed access token");
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        const now = new Date();
        now.setHours(now.getHours() + 1);
        localStorage.setItem('access_token_expires_at', now.getTime());
        return data.access_token;
    } catch (error) {
        console.error('Error:', error);
    }
}

export { getAuthCode, refreshAccessToken, login, logout }
