import '../App.css';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import { useMemo, useState, forwardRef } from 'react';
import { getComparator, stableSort } from '../utils/sort';
import { useLocalStorage } from '../utils/util';
import { checkSongsInLibrary } from '../utils/data';
import { addSongToLibrary, removeSongFromLibrary } from '../utils/import';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { red } from '@mui/material/colors';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CompactAudioPlayer from './CompactAudioPlayer'
import PropTypes from 'prop-types';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * 
 * @param {string} type The type or name of the table; used as a prefix when writing to localStorage so must be unique
 * @param {Array} inputData An array of the songs data to be displayed. Passed by the parent so this table can be easily reused
 * @param {function} setInputData a refereence to the useState setter function for inputData
 * @param {boolean} showInLibrary if true, display songs in the table if they already exist in the user's library
 * @param {boolean} showNoPreview if true, display songs in the table if they lack preview clips
 * @returns 
 */
export default function SongsTable({ type = 'songs', inputData = [], setInputData, showInLibrary = true, showNoPreview = true }) {
    const columns = [
        { id: 'num', label: '#', minWidth: 30, sortable: false },
        { id: 'name', label: 'Title', minWidth: 170, align: 'left', sortable: true },
        { id: 'duration', label: 'Time', minWidth: 30, align: 'left', format: (value) => value.toFixed(0), sortable: true },
        { id: 'artists', label: 'Artists', minWidth: 170, align: 'left', sortable: true },
        { id: 'popularity', label: 'Rank', minWidth: 30, align: 'left', sortable: true, info: true, infoMessage: "The song's popularity on the Spotify platform" },
        { id: 'preview', label: 'Play', minWidth: 120, align: 'left', sortable: false },
        { id: 'link', label: 'Link', minWidth: 170, align: 'center', sortable: false, info: true, infoMessage: 'Click the image to play on Spotify' },
        { id: 'library', label: 'Like', minWidth: 50, align: 'left', sortable: false }
    ];

    const [page, setPage] = useLocalStorage(`${type}CurrentPage`, 0)
    const [rowsPerPage, setRowsPerPage] = useLocalStorage(`${type}RowsPerPage`, 10);
    const [order, setOrder] = useLocalStorage(`${type}SortOrder`, 'desc');
    const [orderBy, setOrderBy] = useLocalStorage(`${type}OrderBy`, 'popularity');

    const [heartSnackbarOpen, setHeartSnackbarOpen] = useState(false);
    const [heartSnackbarMessage, setHeartSnackbarMessage] = useState('');

    const handleHeartSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setHeartSnackbarOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleHeartClick = async (row) => {
        if (row.inLibrary) {
            await removeSongFromLibrary(row.id)
            setHeartSnackbarMessage('Removed from Liked Songs')
        }
        else {
            await addSongToLibrary(row.id)
            setHeartSnackbarMessage('Added to Liked Songs')
        }
        const newInputData = inputData.map((song, index) => {
            if (index === row.index) {
                return { ...song, inLibrary: !row.inLibrary }
            }
            return song
        })
        setInputData(newInputData)
        setHeartSnackbarOpen(true);
    }

    const sortColumn = (property) => {
        //isAsc is only true if the selected property matches and the current order is ascending
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc'); //toggle the order if necessary
        setOrderBy(property);
    };

    //check whether the song exists in the user's library and add to the data; primarily used for recommendations and import songs
    useMemo(
        async () => {
            if (inputData.length === 0) return
            if ('inLibrary' in inputData[0]) return //if the inLibrary flag has already been set, don't set it again
            const ids = inputData.map(song => song.id)
            const results = await checkSongsInLibrary(ids)
            const newInputData = inputData.map((song, index) => {
                return { ...song, inLibrary: results[index] }
            })
            setInputData(newInputData)
            setPage(0) //reset to the first page since this memo is only used by recommendations/import and the page state doesn't need to be remembered when it's refreshed
        }, [inputData, setInputData, setPage]
    )

    //save the rows that the user wants displayed so the filter doesn't need to be run repeatedly
    const rowsToDisplay = useMemo(() =>
        inputData.filter((row) => showInLibrary || !row.inLibrary)
            .filter((row) => showNoPreview || row.preview),
        [showInLibrary, showNoPreview, inputData]
    )

    //the rows that are actually displayed in the table based on the pagination
    const visibleRows = useMemo(
        () =>
            stableSort(rowsToDisplay,
                getComparator(order, orderBy)).slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                ),
        [rowsToDisplay, order, orderBy, page, rowsPerPage]
    );

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            < TableContainer sx={{ maxHeight: { md: '80vh' }, bgcolor: 'PaleTurquoise' }}>
                <Table stickyHeader aria-label="sticky table" padding='none'>
                    <TableHead>
                        <TableRow key='song-header'>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                >
                                    {
                                        column.sortable ? (
                                            <TableSortLabel onClick={() => sortColumn(column.id)}
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'desc'}
                                            >
                                                {column.info ? (
                                                    <Tooltip title={column.infoMessage} arrow>
                                                        <div>
                                                            {column.label}
                                                        </div>
                                                    </Tooltip>
                                                ) : column.label
                                                }
                                            </TableSortLabel>
                                        ) : (
                                            column.info ? (
                                                <Tooltip title={column.infoMessage} arrow>
                                                    <div>
                                                        {column.label}
                                                    </div>
                                                </Tooltip>
                                            ) : column.label
                                        )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            return (
                                <TableRow hover tabIndex={-1} key={row.id} >
                                    <TableCell key='num' sx={{ marginLeft: 1 }} > {rowsPerPage * page + index + 1}</TableCell>
                                    <TableCell key='name' sx={{ fontWeight: 'bold' }}> {row.name}</TableCell>
                                    <TableCell key='duration' > {row.duration} </TableCell>
                                    <TableCell key='artists' > {row.artists}</TableCell>
                                    <TableCell key='popularity' > {row.popularity}</TableCell>
                                    <TableCell key='preview' > {row.preview && <CompactAudioPlayer src={row.preview} />}</TableCell>
                                    <TableCell key='link' align='center'>
                                        <Tooltip title="Play on Spotify" placement='left-end'>
                                            <a href={row.link} target="_blank" rel="noreferrer"><img src={row.image} alt="Song's Album Cover"></img></a>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell key='library' >
                                        {row.inLibrary ? (
                                            <Tooltip title="Remove from Liked Songs" arrow>
                                                <FavoriteIcon sx={{ color: red[800] }} onClick={() => handleHeartClick(row)} />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Add to Liked Songs" arrow>
                                                <FavoriteBorderIcon onClick={() => handleHeartClick(row)} />
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer >
            <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={rowsToDisplay.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Snackbar open={heartSnackbarOpen} autoHideDuration={3000} onClose={handleHeartSnackbarClose} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <Alert onClose={handleHeartSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    {heartSnackbarMessage}
                </Alert>
            </Snackbar>
        </Paper >
    );
}

SongsTable.propTypes = {
    type: PropTypes.string,
    inputData: PropTypes.array,
    setInputData: PropTypes.func,
    showInLibrary: PropTypes.bool,
    showNoPreview: PropTypes.bool
}