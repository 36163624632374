import { Box, Container, Paper, Typography } from "@mui/material";

export default function AboutPage() {
    return (
        <Container maxWidth='lg' sx={{ flex: 1, marginTop: 1 }}>
            <Paper sx={{ flex: 1, flexGrow: 1 }}>
                <Box margin={2}>
                    <Typography variant="h5" padding={2}>Welcome to Tools For Spotify</Typography>
                    <Typography align="left" variant='body1' component='p' padding={2}>
                        The purpose of this site is to add some additional functionality that isn't available in the traditional Spotify app or website. It's a work in progress
                        and more features will be added soon. Please give it a try and send me any feedback to: tools.spotify@outlook.com
                    </Typography>
                    <Typography variant="h5" padding={2}>How to Use</Typography>
                    <Typography align="left" variant='body1' component='p' padding={2}>
                        <ul>
                            <li>This site is currently in closed beta and you must first be granted access in order to use the service. Access can be requested by sending an email to: tools.spotify@outlook.com</li>
                            <li>Begin by logging in to your spotify account by clicking on the avatar icon at the top right and choosing 'Log In'. This will redirect you to the Spotify
                                website where you can log in and grant limited access to your Spotify account. Only the necessary permissions are granted and none of your personal information
                                will be read or stored by this site.</li>
                            <li>After logging in, you will be directed to the profile page which will show your top songs and artists you've listened to on Spotify over the last month.</li>
                            <li>The <b>Songs</b> page allows you to interact with your Library. Click on the album image to open the Spotify page for the song. Remove songs from your library
                                by clicking on the 'heart' icon. You can download your entire library to a CSV file so you can import it at other streaming services or send to friends.</li>
                            <li>The <b>Podcasts</b> page allows you to interact with all the podcasts you subscribe to. Play episodes and easily search through past episodes. Download
                                the show notes for all past episodes to make it even easier to find the show you're looking for.</li>
                            <li>The <b>Import Songs</b> page lets you read a CSV file to import songs to a new playlist in your library. Coming soon, option to import songs directly to your library.</li>
                            <li>The <b>Recommendations</b> page is the best feature of the site. Begin by choosing up to a total of 5 seeds which can be any of songs, artists or genres at least one seed (total, not one of
                                each category) is required. Optionally, you may use the sliders to search for songs with various song features such as Energy, Popularity, Tempo, etc. Features can be toggled
                                on and off as desired.</li>
                            <li>The recommendation results view shows the 50 songs that best match your search criteria. You can use the preview audio widget to listen to a 30 second preview and add to your library
                                by clicking the heart icon if you like the song. Or click on the album artwork under the link column to go to the song's Spotify page.</li>
                        </ul>
                    </Typography>
                    <Typography variant="h5" padding={2}>Copyright</Typography>
                    <Typography align="left" variant='body1' component='p' padding={2}>
                        © Christopher Won 2023
                    </Typography>
                </Box>
            </Paper>
        </Container >
    )
}