import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

let logo_URL = ''

if (process.env.REACT_APP_ENV === 'dev') {
    //Deploy to Development environment
    logo_URL = '/dev/Spotify_Logo_RGB_Black.png'
} else if (process.env.REACT_APP_ENV === 'test') {
    //Local dev environment code
    logo_URL = '/Spotify_Logo_RGB_Black.png'
} else {
    // Production code
    logo_URL = '/Spotify_Logo_RGB_Black.png'
}

const pages = [
    {
        name: 'Terms and Conditions',
        link: <Link to={'terms'}>Terms of Use</Link>
    },
    {
        name: 'Privacy',
        link: <Link to={'/privacy'}>Privacy</Link>
    }
];

export default function AppFooter() {
    return (
        <AppBar position="static" sx={{ backgroundColor: "deepskyblue", marginTop: 'auto' }}>
            <Container maxWidth="md">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                sx={{ my: 2, mx: { xs: 0, sm: 2 }, color: 'white' }}
                            >
                                {page.link}
                            </Button>
                        ))}
                    </Box>
                    <Typography sx={{ my: 2, ml: 2, color: 'black' }}>Content Provided By:</Typography>
                    <img src={logo_URL} alt="Spotify's Logo" style={{ width: '100px', height: 'auto', margin: '15px' }} />
                </Toolbar>
            </Container>
        </AppBar>
    );
}