import { refreshAccessToken } from "./auth.js";

/**
 * 
 * Gets user profile
 */
async function getProfile() {
    let accessToken = await refreshAccessToken();

    const response = await fetch('https://api.spotify.com/v1/me', {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    });

    const profile = await response.json();
    console.log(profile);

    const display_name = profile.display_name
    const user_id = profile.id
    const profile_image = profile.images[0]

    localStorage.setItem('display_name', display_name)
    localStorage.setItem('user_id', user_id)
    localStorage.setItem('profile_image', profile_image)

    return profile
}

/**
 * 
 * Gets user's show information
 * @param {int} limit the number of results to return
 * @param {int} offset the offset to begin retrieving from
 * @returns a data object with the user's show information
 */
async function getShows(limit, offset) {
    let accessToken = await refreshAccessToken();
    let args = new URLSearchParams({
        limit: limit,
        offset: offset
    });

    const response = await fetch('https://api.spotify.com/v1/me/shows?' + args, {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    });

    const data = await response.json();
    console.log(data);
    return data.items;
}

/**
 * 
 * Gets user's show information
 * @param {string} id The Spotify ID for the episode to fetch
 * @param {int} limit the number of results to return
 * @param {int} offset the offset to begin retrieving from
 * @returns a data object with the podcast's information
 */
async function getShowEpisodes(id, limit, offset) {
    let accessToken = await refreshAccessToken();
    let args = new URLSearchParams({
        market: 'CA',
        limit: limit,
        offset: offset
    });

    const response = await fetch('https://api.spotify.com/v1/shows/' + id + '/episodes?' + args, {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    });

    const data = await response.json();
    console.log(data);
    return data.items;
}

/**
 * 
 * Gets user's song tracks
 * @param {int} limit the number of results to return
 * @param {int} offset the offset to begin retrieving from
 * @returns a Promise that resolves to the user's song track information
 */
async function getTracks(limit, offset) {
    let accessToken = await refreshAccessToken();
    let args = new URLSearchParams({
        limit: limit,
        offset: offset
    });

    const response = await fetch('https://api.spotify.com/v1/me/tracks?' + args, {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    });

    return response;
}

/**
 * Preloads all the song data from a user's library when they do a fresh log in so that the Recommendations and Songs have good data
 * and performance isn't slow. 
 * 
 */
async function preloadSongs() {
    //We don't know how many songs are in the user's library, so first we must get tracks once so that we can read the 'total' field send with the response
    //Then we can fetch the rest in parallel, subject to rate-limits
    let firstResult = []

    try {
        const result = await getTracks(50, 0)
        firstResult = await result.json()
    }
    catch (error) {
        console.error(error);
    }

    if (firstResult.total > 50) {
        try {
            //one element for time we have to call getTracks. basically a range sequence function; returns [1,2,3,...n] if firstResult.total/50 = n
            const seeds = Array.from({ length: Math.floor(firstResult.total / 50) }, (_, i) => i + 1)
            const requests = seeds.map((seed) => getTracks(50, seed * 50));
            const responses = await Promise.all(requests);
            const errors = responses.filter((response) => !response.ok);

            if (errors.length > 0) {
                throw errors.map((response) => Error(response.statusText));
            }

            const json = responses.map((response) => response.json());
            const data = await Promise.all(json);
            const items = data.map(result => result.items).flat()
            return firstResult.items.concat(items)
        }
        catch (errors) {
            console.error(errors);
        }
    }
    return firstResult
}

/**
 * 
 * Gets all of a user's song tracks
 * @param {int} limit the number of results to return
 * @param {int} offset the offset to begin retrieving from
 * @returns a data object with the user's song track information
 */
async function getAllTracks(limit = 50, offset = 0, tracks = []) {
    let accessToken = await refreshAccessToken();
    let args = new URLSearchParams({
        limit: limit,
        offset: offset
    });

    const response = await fetch('https://api.spotify.com/v1/me/tracks?' + args, {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    });

    //Recursively fetch all tracks in the library; there are remaining tracks while next is not an empty string
    if (response.ok) {
        const data = await response.json();
        console.log(data);
        tracks.push(...data.items)

        if (data.next) {
            return getAllTracks(limit, limit + offset, tracks)
        } else {
            return tracks
        }
    } else {
        console.error("Error getting all tracks");
    }
}

/**
 * 
 * Gets all of a user's song tracks
 * @param {string} id the Spotify ID for the song
 * @returns a data object with the song's audio features
 */
async function getSongFeatures(id) {
    let accessToken = await refreshAccessToken();

    const response = await fetch('https://api.spotify.com/v1/audio-features/' + id, {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    });

    const data = await response.json();
    console.log(data);
    return data;
}

/**
 * 
 * Gets all of a user's song tracks
 * @param {string} type either 'artists' or 'tracks'
 * @param {string} time_range either 'long_term', 'medium_term', 'short_term'
 * @param {int} limit the number of results to return
 * @param {int} offset the offset to begin retrieving from
 * @returns a data object with the user's top artists/tracks
 */
async function getUsersTopItems(type, time_range, limit, offset) {
    let accessToken = await refreshAccessToken();

    let args = new URLSearchParams({
        time_range: time_range,
        limit: limit,
        offset: offset
    });

    const response = await fetch(`https://api.spotify.com/v1/me/top/${type}?${args}`, {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    });

    const data = await response.json();
    return data.items;
}

/**
 * 
 */
async function getRecommendations(inputs) {
    let accessToken = await refreshAccessToken();

    //min/max/target acousticness, duration, instrumentalness, key, liveness, 
    //loudness, mode, speechiness, signature, valence
    let args = new URLSearchParams({
        market: 'CA',
        ...inputs
    });

    const response = await fetch('https://api.spotify.com/v1/recommendations?' + args, {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    });

    const data = await response.json();
    return data;
}

/**
 * Should rarely change, if ever, so call once on the initial page render
 * @returns an array of available genres used for recommendations
 */
async function getGenreSeeds() {
    let accessToken = await refreshAccessToken();

    const response = await fetch('https://api.spotify.com/v1/recommendations/available-genre-seeds', {
        headers: {
            Authorization: 'Bearer ' + accessToken
        }
    });

    const data = await response.json();
    return data.genres;
}

/**
 * 
 * @param {Array} ids An of the Spotify IDs. For example: ids=4iV5W9uYEdYUVa79Axb7Rh,1301WleyT98MSxVHPZCA6M. Maximum: 50 IDs.
 * @returns An array of booleans signifying which songs are already present in the user's library
 */
async function checkSongsInLibrary(idList) {
    const accessToken = await refreshAccessToken();
    let allResults = []

    for (let i = 0; i < idList.length; i += 50) {
        let ids = idList.slice(i, i + 50)
        try {
            const response = await fetch(`https://api.spotify.com/v1/me/tracks/contains?ids=${ids}`,
                {
                    headers: {
                        Authorization: 'Bearer ' + accessToken,
                        'Content-Type': 'application/json'
                    },
                    method: "GET"
                });
            const results = await response.json()
            allResults = allResults.concat(results)
        } catch (error) {
            console.error('Error checking if songs are present in library ', error);
        }
    }
    return allResults
}

export { getProfile, getTracks, getShows, getAllTracks, getShowEpisodes, getSongFeatures, getUsersTopItems, getRecommendations, getGenreSeeds, checkSongsInLibrary, preloadSongs };