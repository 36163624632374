import { Box, Container, Paper, Typography } from "@mui/material";

export default function LogoutPage() {
    return (
        <Container maxWidth='lg' sx={{ flex: 1, marginTop: 1 }}>
            <Paper sx={{ flex: 1, flexGrow: 1 }}>
                <Box margin={2}>
                    <Typography variant="h5" padding={2}>Thanks for using Tools For Spotify</Typography>
                    <Typography align="left" variant='body1' component='p' padding={2}>
                        You have been successfully logged out. Log back in to continue using the site.
                    </Typography>
                    <Typography variant="h5" padding={2}>Copyright</Typography>
                    <Typography align="left" variant='body1' component='p' padding={2}>
                        © Christopher Won 2023
                    </Typography>
                </Box>
            </Paper>
        </Container >
    )
}