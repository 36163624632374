import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { logout } from '../utils/auth';

/**
 * 
 * @param open React state variable, true when the dialog is open
 * @param setOpen React state setter function
 * @returns 
 */
export default function LogoutDialog({ open = false, setOpen = null }) {

  /**
   * Clears the user's data from localstorage and reloads the home page 
   * to wipe out the prefetched data that isn't stored in React state
   */
  const handleClose = async () => {
    setOpen(false);
    logout()

    const url = new URL(window.location.href);
    if (url.pathname.includes('/dev')) {
      url.pathname = '/dev/index.html';
    } else {
      url.pathname = '/';
    }
    window.location.href = url;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Successfully Logged Out
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Log back in to continue using the site. Redirecting to the home page...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

LogoutDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
}