import '../App.css';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { preloadSongs } from '../utils/data'
import { useLocalStorage } from '../utils/util';
import SongsTable from './SongsTable';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

export default function SongsPage() {
    const [songsData, setSongsData] = useLocalStorage('songsData', []);
    const [csvData, setCSVData] = useLocalStorage('songsCSVData', []);

    const loadSongs = async ({ limit, offset }) => {
        const downloadData = ["Song Name,Artists,Album Name,Duration(ms),Release Year,International Standard Recording Code(ISRC),Spotify URI\n"]
        const data = await preloadSongs()
        // const data = await getAllTracks(limit, offset)
        const parsedData = data.map((row, index) => {
            const track = row.track
            const name = track.name.replace(/"/g, "") //strip out quotes from within song/album titles
            const artists = track.artists.map((artist) => artist.name).join(", ")
            const artistsIDs = track.artists.map((artist) => { return { name: artist.name, id: artist.id } })
            const duration = new Date(track.duration_ms).toISOString().slice(15, 19); //change to m:ss format

            downloadData.push(`"${name}", "${artists}", "${track.album.name}", ${track.duration_ms}, ${track.album.release_date.slice(0, 4)} ,${track.external_ids.isrc}, ${track.uri}\n`)
            return {
                name: track.name, duration: duration, artists: artists, popularity: track.popularity, link: track.external_urls.spotify,
                image: track.album.images[2].url, id: track.id, artistsIDs: artistsIDs,
                preview: track.preview_url, inLibrary: true, index: index
            }
        })
        setSongsData(parsedData);
        setCSVData(downloadData)
    }

    /***
     * Creates a csv file with the list of the user's songs 
     */
    const downloadCSV = async () => {
        const blob = new Blob(csvData, { type: "text/csv" });
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = "userlibrary.csv"
        a.click();
        window.URL.revokeObjectURL(a.href);
    }

    return (
        <Paper elevation={20} sx={{ width: '100%', overflow: 'hidden' }} >
            <Grid container spacing={2} margin={0.5}>
                <Grid xs={8} sm={6} md={4} lg={3} xl={2} xsOffset={2} smOffset={0}>
                    <Button sx={{ width: '100%' }} name='load-songs' color='secondary' variant='contained' onClick={() => loadSongs({ limit: 50, offset: 0 })}>Refresh Songs</Button>
                </Grid>
                <Grid xs={8} sm={6} md={4} lg={3} xl={2} xsOffset={2} smOffset={0} mdOffset='auto' >
                    <Button sx={{ width: '100%' }} name='download' color='secondary' variant='contained' onClick={downloadCSV}>Download Library as CSV</Button>
                </Grid>
            </Grid>
            <SongsTable type='songs' inputData={songsData} setInputData={setSongsData} />
        </Paper >
    )
}