import { Paper, TextField, Typography, Button } from "@mui/material";
import SongsTable from "./SongsTable";
import { useLocalStorage } from "../utils/util";
import { MuiFileInput } from 'mui-file-input'
import { useState, forwardRef } from "react";
import { addTracksToPlaylist, createPlaylist, importCSV } from "../utils/import";
import ProgressWithBackdrop from "./ProgressWithBackdrop";
import Grid from '@mui/material/Unstable_Grid2';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ImportPage() {
    const [playlistSnackbarOpen, setPlaylistSnackbarOpen] = useState(false)
    const [importData, setImportData] = useLocalStorage('importData', [])
    const [showTable, setShowTable] = useLocalStorage('showImportTable', false)
    const [file, setFile] = useState(null)
    const [playlistName, setPlaylistName] = useLocalStorage('importPlaylistName', '')
    const [progressValue, setProgressValue] = useState(0)
    const [backdropOpen, setBackdropOpen] = useState(false)

    const handleSnackbarClose = () => {
        setPlaylistSnackbarOpen(false)
    }

    const handleFileChange = (value) => {
        setFile(value)
        console.log(value);
    }

    const loadImportData = async () => {
        const searchResults = await importCSV(file, playlistName, setBackdropOpen, setProgressValue)
        const parsedData = searchResults.success.map((row, index) => {
            const track = row[0]
            const artists = track.artists.map((artist) => { return artist.name }).join(", ")
            return {
                name: track.name, duration: Math.round(track.duration_ms / 1000), artists: artists,
                popularity: track.popularity, link: track.external_urls.spotify, image: track.album.images[2].url,
                uri: track.uri, id: track.id, preview: track.preview_url, index: index
            }
        })
        setImportData(parsedData)
        setShowTable(true)
    }

    const writePlaylist = async () => {
        const songList = importData.map((i) => i.uri)
        const playlistID = await createPlaylist(playlistName)
        addTracksToPlaylist(songList, playlistID)
        setPlaylistSnackbarOpen(true)
    }

    const resetForm = () => {
        setFile('')
        setPlaylistName('')
        setShowTable(false)
        setImportData([])
    }

    const gridProps = {
        xs: 8,
        xsOffset: 2,
        sm: 6,
        smOffset: 3,
        md: 4,
        mdOffset: 4,
        xl: 2,
        xlOffset: 5
    }

    return (
        <Paper elevation={20}>
            <Typography variant="h4" sx={{ color: 'blue', textAlign: 'center' }}>Import from CSV</Typography>
            <Grid container rowSpacing={5} columnSpacing={5} margin={2} >
                <Grid {...gridProps}>
                    <TextField sx={{ width: '100%' }} required id="playlist-name" label="Name of Playlist to Create" variant="outlined" size="small"
                        value={playlistName}
                        onChange={(event) => {
                            setPlaylistName(event.target.value);
                        }}
                    />
                </Grid>
                <Grid {...gridProps}>
                    <MuiFileInput sx={{ width: '100%' }} value={file} onChange={handleFileChange} label='Select a CSV file to import' required size="small" />
                </Grid>
                <Grid {...gridProps}>
                    <Button sx={{ width: '100%' }} variant="contained" color="primary" size="large" onClick={resetForm}>Reset Form</Button>
                </Grid>
                <Grid {...gridProps}>
                    <Button sx={{ width: '100%' }} variant="contained" color="warning" size="large" onClick={loadImportData}>Read CSV File</Button>
                </Grid>
                <Grid {...gridProps}>
                    <Button sx={{ width: '100%' }} variant="contained" color="success" size="large" onClick={writePlaylist}>Create Playlist</Button>
                </Grid>
            </Grid>
            {backdropOpen && <ProgressWithBackdrop open={backdropOpen} setOpen={setBackdropOpen} progress={progressValue} />}
            {showTable && <SongsTable type="import" inputData={importData} setInputData={setImportData} />}

            <Snackbar open={playlistSnackbarOpen} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Playlist Created!
                </Alert>
            </Snackbar>

        </Paper>
    )
}