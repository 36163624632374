import { Paper, Link, Box, useTheme, useMediaQuery, Typography } from "@mui/material";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import PropTypes from 'prop-types';

const ImageListItemBarColor = 'LightYellow'

export default function ProfilePage({ topTracks, topArtists }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.custom.isSmallScreen);
    const isMediumScreen = useMediaQuery(theme.custom.isMediumScreen);
    const isLargeScreen = useMediaQuery(theme.custom.isLargeScreen);

    let imageListCols = 6; // Default number of columns for my 4K monitor
    let imageListMargin = 5;

    if (isSmallScreen) {
        imageListCols = 2;
        imageListMargin = 1;
    } else if (isMediumScreen) {
        imageListCols = 3;
        imageListMargin = 2;
    } else if (isLargeScreen) {
        imageListCols = 4;
        imageListMargin = 3;
    }

    //Some of the images are 268x320, not 320x320! So a lot of extra css is required to control the image size/shape
    return (
        <Box>
            {/* <Typography variant="h4" textAlign="left" color='green'>Welcome {display_name}!</Typography> */}
            {/* <Typography variant="h4" textAlign="right">Your Spotify User ID is: {user_id} </Typography> */}
            {/* <Typography textAlign="center">{profile_image}</Typography> */}

            <Paper elevation={24} sx={{ margin: 2, padding: 2 }} >
                <Typography variant="h5" sx={{ color: 'blue', textAlign: 'center' }}>Your Top Songs This Month</Typography>
                <ImageList cols={imageListCols} gap={5} sx={{ margin: imageListMargin, mt: 2, maxWidth: '100%' }}>
                    {topTracks.map((item) => (
                        <ImageListItem key={item.name} sx={{ maxWidth: '100%', overflowX: 'hidden' }}>
                            <img
                                srcSet={`${item.image} 300w, ${item.image_mobile} 64w`}
                                sizes="(max-width: 600px) 64px,
                                        300px"
                                src={item.image}
                                alt={item.name}
                                loading="lazy"
                            />
                            <ImageListItemBar
                                title={item.name}
                                subtitle={item.artists}
                                position="below"
                                sx={{ backgroundColor: ImageListItemBarColor }}
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`info about ${item.name}`}
                                    >
                                        <Link href={item.url} target="_blank" rel="noreferrer">
                                            <PlayCircleFilledOutlinedIcon fontSize="small" />
                                        </Link>
                                    </IconButton>
                                }
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Paper>
            <Paper elevation={24} sx={{ margin: 2, padding: 2 }}>
                <Typography variant="h5" sx={{ color: 'blue', textAlign: 'center' }}>Your Top Artists This Month</Typography>
                <ImageList cols={imageListCols} gap={5} sx={{ margin: imageListMargin, mt: 2 }} >
                    {topArtists.map((item) => (
                        <ImageListItem key={item.name} >
                            <div
                                //WORKS BEST
                                style={{
                                    width: '100%',
                                    paddingTop: '100%', // Maintain 1:1 aspect ratio
                                    position: 'relative',
                                    overflow: 'hidden',
                                    backgroundColor: 'lightgray'
                                }}>
                                <img
                                    srcSet={`${item.image} 320w, ${item.image_mobile} 160w`}
                                    sizes="(max-width: 600px) 160px,
                                        320px"
                                    src={item.image}
                                    alt={item.name}
                                    loading="lazy"
                                    //WORKS BEST
                                    style={{
                                        objectFit: 'contain',
                                        width: '100%',
                                        maxHeight: '100%',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                    }}
                                />
                            </div>
                            <ImageListItemBar
                                title={item.name}
                                position="below"
                                sx={{ backgroundColor: ImageListItemBarColor }}
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                        aria-label={`info about ${item.name}`}
                                    >
                                        <Link href={item.url} target="_blank" rel="noreferrer">
                                            <LaunchOutlinedIcon fontSize="small" />
                                        </Link>
                                    </IconButton>
                                }
                            />

                        </ImageListItem>
                    ))}
                </ImageList>
            </Paper>
        </Box >
    );
}

ProfilePage.propTypes = {
    topArtists: PropTypes.array,
    topTracks: PropTypes.array
}