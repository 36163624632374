import './App.css';
import React, { forwardRef, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import MainAppBar from './components/MainAppBar';
import { Routes, Route } from "react-router-dom";
import ProfilePage from './components/ProfilePage';
import SongsPage from './components/SongsPage';
import PodcastsTable from './components/PodcastsTable';
import ImportPage from './components/ImportPage';
import RecommendationPage from './components/RecommendationPage';
import AboutPage from './components/AboutPage';
import { getUsersTopItems, preloadSongs } from './utils/data';
import { getAuthCode } from './utils/auth';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Button, ThemeProvider, Typography, createTheme } from '@mui/material';
import AppFooter from './components/AppFooter';
import EULAPage from './components/EULAPage';
import PrivacyPage from './components/PrivacyPage';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LogoutPage from './components/LogoutPage';

const mainTheme = createTheme({
  components: {
    MuiImageListItemBar: {
      styleOverrides: {
        title: { // Adjust the font size for the title
          fontSize: 12,
          fontWeight: 'bold'
        },
        subtitle: {
          fontSize: 12, // Adjust the font size for the subtitle
        },
      },
    },
  },

  //custom standard media query strings to be used in media queries in individual components
  custom: {
    isSmallScreen: '@media (max-width: 600px)',
    isMediumScreen: '@media (min-width: 601px) and (max-width: 960px)',
    isLargeScreen: '@media (min-width: 961px) and (max-width: 1920px)',
    isExtraLargeScreen: '@media (min-width: 1921px)'
  }
})

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const usersTopTracks = async () => {
  const results = await getUsersTopItems('tracks', 'short_term', 24, 0)
  console.log(results);

  const topTrackList = results.map((song) => {
    const artists = song.artists.map((artist) => { return artist.name }).join(", ")
    return { name: song.name, artists: artists, image_mobile: song.album.images[2].url, image: song.album.images[1].url, url: song.external_urls.spotify }
  })
  return topTrackList
}

const usersTopArtists = async () => {
  const results = await getUsersTopItems('artists', 'short_term', 12, 0)
  console.log(results);

  const topArtistList = results.map((artist) => {
    return { name: artist.name, image_mobile: artist.images[2].url, image: artist.images[1].url, url: artist.external_urls.spotify }
  })
  return topArtistList
}

/**
 * Perform an initial load of the user's library so that the songs and recommendation pages have good starting data
 */
async function loadInitialSongsData() {
  const downloadData = ["Song Name,Artists,Album Name,Duration(ms),Release Year,International Standard Recording Code(ISRC),Spotify URI\n"]
  // const data = await getAllTracks(50, 0)
  const data = await preloadSongs()
  const parsedData = data.map((row, index) => {
    const track = row.track
    const name = track.name.replace(/"/g, "") //strip out quotes from within song/album titles
    const artists = track.artists.map((artist) => artist.name).join(", ")
    const artistsIDs = track.artists.map((artist) => { return { name: artist.name, id: artist.id } })
    const duration = new Date(track.duration_ms).toISOString().slice(15, 19); //change to m:ss format
    downloadData.push(`"${name}", "${artists}", "${track.album.name}", ${track.duration_ms}, ${track.album.release_date.slice(0, 4)} ,${track.external_ids.isrc}, ${track.uri}\n`)
    return {
      name: track.name, duration: duration, artists: artists, popularity: track.popularity, link: track.external_urls.spotify,
      image: track.album.images[2].url, id: track.id, artistsIDs: artistsIDs,
      preview: track.preview_url, inLibrary: true, index: index
    }
  })
  localStorage.setItem('songsData', JSON.stringify(parsedData))
  localStorage.setItem('songsCSVData', JSON.stringify(downloadData))
  return parsedData
}

const token = localStorage.getItem('refresh_token')

const topTracks = token ? await usersTopTracks() : null
const topArtists = token ? await usersTopArtists() : null
const songsData = token ? await loadInitialSongsData() : null

export default function App() {
  //loading the data here without await introduces a race condition with the recommendations page if the user clicks there before the data is loaded
  //loading it outside this function leads to a slower initial page load especially for larger libraries
  const [snackbarOpen, setSnackbarOpen] = useState(!token);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
    getAuthCode()
  };

  const handleSnackbarReject = () => {
    setSnackbarOpen(false);
    setErrorSnackbarOpen(true);
  }

  const handleReopenSnackbar = () => {
    setSnackbarOpen(true);
    setErrorSnackbarOpen(false);
  }

  const action = (
    <React.Fragment>
      <Button color="success" size="small" aria-label="accept" onClick={handleSnackbarClose}>
        Accept<CheckIcon />
      </Button>
      <Button color="error" size="small" aria-label="reject" onClick={handleSnackbarReject}>
        Reject<CloseIcon fontSize="small" />
      </Button>
    </React.Fragment>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        {token ? (
          <Routes>
            <Route path="/" element={<MainAppBar />}>
              <Route index element={<ProfilePage topTracks={topTracks} topArtists={topArtists} />} />
              <Route path="songs" element={<SongsPage />} />
              <Route path="podcasts" element={<PodcastsTable />} />
              <Route path="import" element={<ImportPage />} />
              <Route path="recommendations" element={<RecommendationPage songsData={songsData} />} />
              <Route path="profile" element={<ProfilePage topTracks={topTracks} topArtists={topArtists} />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="terms" element={<EULAPage />} />
              <Route path="privacy" element={<PrivacyPage />} />
              <Route path="logout" element={<LogoutPage />} />
              <Route path="*" element={<ProfilePage topTracks={topTracks} topArtists={topArtists} />} />
            </Route>
          </Routes>
        ) : (
          <div>
            <Typography variant='h4' textAlign='center'>Welcome to Tools For Spotify</Typography>
            <Typography variant='h6' textAlign='center' color='red' margin={2}>Please review and accept the End User License Agreement and Privacy Policy to continue.</Typography>
            <EULAPage />
            <PrivacyPage />
            <Snackbar action={action} open={snackbarOpen} onClose={handleSnackbarClose} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              message='Accept and log in to Spotify to continue'>
            </Snackbar>
            <Snackbar open={errorSnackbarOpen} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
              <Alert onClose={handleReopenSnackbar} severity="error" sx={{ width: '100%' }}>
                You must accept the EULA and Privacy Policy to continue
              </Alert>
            </Snackbar>
          </div>
        )}
        <footer style={{ marginTop: 'auto' }}>
          <AppFooter />
        </footer>
      </ThemeProvider>
    </div>
  );
}