import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import MenuItem from '@mui/material/MenuItem';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { Link, Outlet } from 'react-router-dom';
import { login } from '../utils/auth';
import { useState } from "react";
import LogoutDialog from './LogoutDialog';

const pages = [
    {
        name: 'Home',
        link: <Link to={'/'}>Home</Link>
    },
    {
        name: 'Songs',
        link: <Link to={'/songs'}>Songs</Link>
    },
    {
        name: 'Podcasts',
        link: <Link to={'/podcasts'}>Podcasts</Link>
    },
    {
        name: 'Import',
        link: <Link to={'/import'}>Import</Link>
    },
    {
        name: 'Recommendations',
        link: <Link to={'/recommendations'}>Recommendations</Link>
    },
    {
        name: 'About',
        link: <Link to={'/about'}>About</Link>
    }
];

//Nav Menu on the left will be permanently open in a large window such as a desktop web browser
//but will collapse on a smaller screen like a phone; the 1st typography is visible when the 
//menu is open, the 2nd typography only shows up when the Nav Menu is collapsed

//The first Box is the collapsed menu and uses MenuItems; the 2nd Box is for when the Nav menu is permanently
//open; the 3rd Box is for the User Menu on the right

export default function MainAppBar() {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [logoutOpen, setLogoutOpen] = useState(false)
    // const [avatarInitials, setAvatarInitials] = useState('CW')

    const openDialog = () => {
        setLogoutOpen(true)
    }

    const settings = [
        {
            name: 'Login',
            link: <Link onClick={login} to={'/'}>Switch User</Link>
        },
        {
            name: 'Profile',
            link: <Link to={'/profile'}>View Profile</Link>
        },
        {
            name: 'Logout',
            link: <Link onClick={openDialog} to={'/logout'}>Log Out</Link>
        }
    ];

    //These methods control the opening/closing of the Nav Menu and the user's 
    //menu Icon and set the anchor of the menu that pops up to be the Icon 
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    //Toolbar contains the actual menu buttons
    return (
        <React.Fragment>
            <AppBar position="static" sx={{ backgroundColor: "deepskyblue" }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <MusicNoteIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'black',
                                textDecoration: 'none',
                            }}
                        >
                            Tools For Spotify
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="navigation button"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                        {page.link}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        <MusicNoteIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.2rem',
                                color: 'black',
                                textDecoration: 'none',
                            }}
                        >
                            Tools For Spotify
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page.name}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, mx: 1, color: 'white', display: 'block' }}
                                >
                                    {page.link}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton data-testid="open-settings-button" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <PersonSharpIcon />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem role={setting.name} key={setting.name} onClick={handleCloseUserMenu}>
                                        {setting.link}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                    <LogoutDialog open={logoutOpen} setOpen={setLogoutOpen} />
                </Container>
            </AppBar>
            <Outlet></Outlet>
        </React.Fragment>
    );
}