import { useState } from "react";
import ChatWindow from "./ChatWindow";
import { refreshAccessToken } from "../utils/auth";
import PropTypes from 'prop-types';

let AIEndpoint = 'https://music.chriswon.ca/assistant'
if (process.env.REACT_APP_ENV !== 'prod') {
    AIEndpoint = 'https://music.chriswon.ca/dev/assistant/'
}


const initialMessages = [{
    text: "Welcome! Tell me about what kind of music you're looking for and I'll do my best to fill in the form for you!",
    sender: 'System'
},
{
    text: "For example: 'Help me find happy rock songs that I can dance to' ",
    sender: 'System'
}]

const functionFoundMessage = {
    text: 'OK! I found some songs that you might be interested in.',
    sender: 'System'
}

const functionEndMessage = {
    text: 'Close this window and check out the results. If you want to refine your search, just let me know!',
    sender: 'System'
}


/**
 * OpenAI tool_call may sometimes ignore the provided function definition and return strings 
 * instead of arrays. So test the type and only join if it's actually an array.
 * @param {string} input 
 * @returns joined string
 */
function safeJoin(input) {
    if (Array.isArray(input)) {
        const formatter = new Intl.ListFormat('en', {
            style: 'long',
            type: 'conjunction',
        });
        return formatter.format(input)
    } else {
        return input;
    }
}

export default function OpenAIChatControl({ onClose, updateStateFromChat }) {
    const makeParamMessage = (response) => {
        let message = ''

        if ('seed_tracks' in response) {
            const tracks = safeJoin(response.seed_tracks)
            message += `I'll try to find something that sounds like ${tracks}. `
        }

        if ('seed_artists' in response) {
            const artists = safeJoin(response.seed_artists)
            message += `I'm searching for songs that are similar to songs by ${artists}. `
        }

        if ('seed_genre' in response) {
            const genre = response.seed_genre
            message += `We'll look for songs that are from the ${genre} genre. `
        }

        console.log(message);
        return {
            text: message,
            sender: 'System'
        }
    }

    const sendMessage = async (messages) => {
        let newMessages = null
        const newMessage = messages.at(-1).text
        console.log(newMessage)

        let args = new URLSearchParams({
            message: newMessage
        });

        let accessToken = await refreshAccessToken();
        const reply = await fetch(AIEndpoint + '?' + args, {
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        })
        const result = await reply.json()
        const response = result.response
        console.log(result);
        console.log(response);

        if (result.type === 'message') {
            setChatMessages([...messages, { text: response, sender: 'System' }])
        } else {
            newMessages = await updateStateFromChat(response)
            console.log(newMessages);
            setChatMessages([...messages, functionFoundMessage, makeParamMessage(response), ...newMessages, functionEndMessage])
        }
    }

    const [chatMessages, setChatMessages] = useState(initialMessages);

    return (
        <ChatWindow onClose={onClose} messages={chatMessages} setMessages={sendMessage} />
    )
}

OpenAIChatControl.propTypes = {
    onClose: PropTypes.func.isRequired,
    updateStateFromChat: PropTypes.func.isRequired
}