import React, { useState, useRef, useEffect } from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PropTypes from 'prop-types';

export default function CompactAudioPlayer({ src }) {
    const pauseIconRef = useRef(null);
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        // Focus the PauseCircleIcon when the component mounts
        if (isPlaying && pauseIconRef.current) {
            pauseIconRef.current.focus();
        }
    }, [isPlaying]);

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ' ') togglePlay();
    };

    const handleComplete = () => setIsPlaying(false)

    return (
        <div>
            <audio
                ref={audioRef}
                src={src}
                onEnded={handleComplete}
                preload='metadata'
            />
            {isPlaying ? <PauseCircleIcon onClick={togglePlay} onKeyDown={handleKeyDown} ref={pauseIconRef} tabIndex="0" /> : <PlayCircleIcon onClick={togglePlay} />}
        </div>
    );
};

CompactAudioPlayer.propTypes = {
    src: PropTypes.string.isRequired
}